// @import "../../../node_modules/nouislider/dist/nouislider.min.css";
@import "nouislider/dist/nouislider.css";
@import "../styles/themes/default";
@import "./variables";
@import "./table";
@import "./entry";
@import "./page-tabs";
@import "./button";
@import "./dropdown";
@import "../../../node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "../../../node_modules/@glidejs/glide/src/assets/sass/glide.theme";

body {
  font-family: Avenir, sans-serif;
  height: 100%;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: var(--primary-01);
  word-break: break-word;

  &:hover {
    color: var(--primary-01-01);
  }

  &:not([href]):not([class]) {
    color: var(--primary-01);
  }
}

hr {
  height: 0.5px !important;
}

p,
li,
li::marker,
span,
hr,
caption {
  color: var(--neutral-04);
}

h1,
h2,
h3,
h4,
h5,
h6,
small,
strong {
  color: var(--neutral-07);
}

.invalid-feedback {
  display: block;
  color: var(--primary-03);
  text-align: start;

  span {
    color: var(--primary-03);
  }
}

.valid-feedback {
  display: block;
  color: var(--success-01);
  text-align: start;

  span {
    color: var(--success-01);
  }
}

.bg-color:hover {
  background: var(--neutral-01) !important;
}

.primary-01 {
  color: var(--primary-01);
}

.neutral-07 {
  color: var(--neutral-07);
}

.neutral-04 {
  color: var(--neutral-04);
}

.content {
  margin: 0px 100px 100px 100px;

  @include m {
    margin: 0px 25px 25px 25px;
  }

  &__head {
    margin-bottom: 60px;
  }

  &__heading {
    font-size: 48px;
    font-weight: bold;
    line-height: 1.16667;
    margin-bottom: 0px;
    letter-spacing: -0.02em;
    color: var(--neutral-07);

    @include t {
      font-size: 40px;
    }

    @include m {
      font-size: 30px;
    }
  }

  &__info {
    color: var(--neutral-04);
    margin-top: 12px;
    margin-bottom: 0px;
    font-size: 24px;
    line-height: 1.33333;
    letter-spacing: -0.01em;

    @include m {
      font-size: 18px;
    }
  }

  &__boxed {
    margin: 0px 50px 110px 50px;

    @include m {
      margin: 0px 25px 100px 25px;
    }
  }
}

.footer {
  &__text-1 {
    color: var(--neutral-10);
    margin-bottom: 12px;
    text-align: center;
    @include caption1-m;
  }

  &__text-2 {
    color: var(--neutral-10);
    text-align: center;
    @include caption2-m;

    a {
      color: var(--primary-01) !important;
    }
  }
}

.media {
  &__facebook {
    background: var(--social-facebook) !important;
    color: var(--neutral-01) !important;

    svg {
      color: var(--neutral-00) !important;
    }

    &:hover {
      background: var(--social-facebook) !important;
    }

    &-social {
      color: var(--social-facebook) !important;
      background: var(--neutral-01) !important;

      svg {
        color: var(--social-facebook) !important;
      }
    }
  }

  &__twitter {
    background: var(--social-twitter) !important;
    color: var(--neutral-01) !important;

    svg {
      color: var(--neutral-00) !important;
    }

    &:hover {
      background: var(--social-twitter) !important;
    }

    &-social {
      color: var(--social-twitter) !important;
      background: var(--neutral-01) !important;

      svg {
        color: var(--social-twitter) !important;
      }
    }
  }

  &__pinterest {
    background: var(--social-pinterest) !important;
    color: var(--neutral-01) !important;

    svg {
      color: var(--neutral-00) !important;
    }

    &:hover {
      background: var(--social-pinterest) !important;
    }

    &-social {
      color: var(--social-pinterest) !important;
      background: var(--neutral-01) !important;

      svg {
        color: var(--social-pinterest) !important;
      }
    }
  }

  &__instagram {
    background: var(--social-instagram) !important;
    color: var(--neutral-01) !important;

    svg {
      color: var(--neutral-00) !important;
    }

    &:hover {
      background: var(--social-instagram) !important;
    }

    &-social {
      color: var(--social-instagram) !important;
      background: var(--neutral-01) !important;

      svg {
        color: var(--social-instagram) !important;
      }
    }
  }

  &__linkedIn {
    background: var(--social-linkedin) !important;
    color: var(--neutral-01) !important;

    svg {
      color: var(--neutral-00) !important;
    }

    &:hover {
      background: var(--social-linkedin) !important;
    }

    &-social {
      color: var(--social-linkedin) !important;
      background: var(--neutral-01) !important;

      svg {
        color: var(--social-linkedin) !important;
      }
    }
  }

  &__google {
    background: var(--social-google) !important;
    color: var(--neutral-01) !important;

    svg {
      color: var(--neutral-00) !important;
    }

    &:hover {
      background: var(--social-google) !important;
    }

    &-social {
      color: var(--social-google) !important;
      background: var(--neutral-01) !important;

      svg {
        color: var(--social-google) !important;
      }
    }
  }
}

.header__title {
  margin-bottom: 12px;
  font-size: 52px;
  line-height: 1.16667;
  letter-spacing: -0.02em;
  font-weight: 700;
  color: var(--primary-01);

  @include t {
    font-size: 40px;
  }

  @include m {
    font-size: 30px;
  }
}

.header__info {
  margin-top: 12px;
  font-size: 24px;
  line-height: 1.33333;
  letter-spacing: -0.01em;
  color: var(--neutral-04);

  @include m {
    font-size: 16px;
  }
}

.nav__link {
  display: flex;
  padding: 6px 12px;
  border-radius: 14px;
  background: none;
  font-size: 14px;
  line-height: 1.14286;
  font-weight: 700;
  color: var(--neutral-10);
  transition: all 0.2s;
}

.nav__link.active {
  background: var(--neutral-03-01);
  color: var(--neutral-07);
}

.nav__link:not(:last-child) {
  margin-right: 8px;
}

.search__wrapper {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

.search__input {
  width: 33.33%;

  @include d {
    width: 75%;
  }

  @include m {
    width: 100%;
  }
}

.play_small {
  width: 48px;
  height: 48px;
}

.play_small svg {
  width: 20px;
  height: 20px;
}

.play svg {
  fill: var(--neutral-04);
  transition: all 0.2s;
}

.play {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-shadow: 0px 8px 16px -8px rgb(15 15 15 / 10%);
  border-radius: 50%;
  background: var(--neutral-01);
}

.play:hover svg {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  fill: var(--primary-01);
}

.faq__top {
  max-width: 640px;
  margin: 0 auto 32px;
  text-align: center;
}

.faq__stage {
  margin-bottom: 12px;
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--neutral-10);
}

.faq__title {
  margin-bottom: 20px;
  font-size: 48px;
  line-height: 1.16667;
  letter-spacing: -0.02em;
  font-weight: 700;
  color: var(--primary-01);
}

.faq__info {
  font-size: 18px;
  line-height: 1.5;
  color: var(--neutral-08);
}

.faq__info a {
  font-weight: 500;
  color: var(--primary-01);
}

.pt-5 {
  padding-top: 5rem !important;
}

// FIIT COMPONENT
.fiit_info__wrapper {
  display: flex;
  gap: 1.5rem;

  @include t {
    flex-direction: column;
  }
}

.fiit_info__body,
.fiit_info__image {
  flex: 1;
  width: 50%;

  @include t {
    width: 100%;
  }
}

.fiit_info__body {
  color: var(--neutral-04);
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fiit_info__body_details {
  @include t {
    margin-top: 15px;
  }

  h4 {
    color: var(--neutral-07);
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 22px;
  }

  strong {
    color: var(--neutral-07);
  }
}

.fiit_info__image {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 450px;
    max-height: 400px;
    border-radius: 12px;
    object-position: center;
    object-fit: cover;

    @include t {
      width: unset;
      max-height: 250px;
      object-fit: scale-down;
    }
  }
}

.fiit_info__image.right {
  margin-left: 24px;

  @include t {
    margin-left: 0px;
  }
}

.fiit_info__image.left {
  margin-right: 24px;

  @include t {
    margin-right: 0px;
  }
}

.fiit_showcase__imgs {
  display: flex;

  .fiit_showcase__img:not(:last-child) {
    margin-right: 20px;

    @include m {
      margin-right: 0px;
      margin-bottom: 15px;
    }
  }

  @include m {
    flex-direction: column;
  }
}

.fiit_showcase__footer_img,
.fiit_showcase__img {
  img {
    object-position: center;
    object-fit: cover;
    width: 100%;
    border-radius: 12px;
  }
}

.fiit_showcase__img {
  flex: 1;

  img {
    height: 285px;

    @include m {
      height: auto;
      max-height: 300px;
    }
  }
}

.fiit_showcase__footer_img {
  img {
    max-height: 400px;

    @include d {
      min-height: 200px;
    }

    @include m {
      max-height: 250px;
    }
  }
}

// Sustenaility
.sustainability_partners {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;

  @include d {
    grid-template-columns: repeat(2, 1fr);
  }

  @include t {
    grid-template-columns: repeat(1, 1fr);
  }

  .section__background {
    display: flex;
    flex: 1;
    height: 100%;
  }
}

.corporate__events {
  .section__background {
    display: block;
    flex: 1;
    height: 100%;
  }
}

.sustainability_section__content,
.sustainability_body {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.sustainability_partner__img {
  img {
    width: 100%;
    max-height: 500px;
    border-radius: 12px;
    object-position: center;
    object-fit: cover;

    @include t {
      max-height: 100%;
    }
  }
}

.sustainability_body {
  margin-top: 20px;
}

.sustainability_body__text {
  flex: 1;

  p,
  h2,
  h4 {
    text-align: center;
  }
}

.sustainability_body__cta {
  display: flex;
  justify-content: center;
}

.mt-5 {
  margin-top: 5rem !important;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.footer__btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 0;
  gap: 1rem;
}

.filler {
  flex: 1;
  display: flex;
}

.filler.top {
  justify-content: flex-end;
  align-items: center;
}

.filler.bottom {
  justify-content: flex-start;
}

.input__bottom_info {
  line-height: 24px;
  color: var(--neutral-04);
  font-size: 14px;
  font-weight: 500;
  margin-top: 3px;
}

.input__bottom_checkbox {
  margin-top: 5px;

  .checkbox__text {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
}

.required__label {
  font-size: 14px !important;
  font-weight: 300 !important;
  font-style: italic !important;
  color: var(--danger-01-01) !important;
}

.event__subtitles {
  text-transform: capitalize !important;
}

.events__for__everyone {
  margin: 0 -100px;
  padding-top: 100px;
  position: relative;

  @include m {
    margin: 0 -25px;
  }
}

.events__for__everyone_header {
  h2 {
    color: var(--neutral-11);
    font-size: 60px;
  }
}

.events__for__everyone_carrousel,
.events__for__everyone_header {
  z-index: 2;
  position: inherit;
}

.events__for__everyone_background {
  position: absolute;
  background: var(--primary-01-04);
  ;
  width: 100%;
  top: 0;
  height: 550px;
}


.popular__regions {
  .card__10_wrapper {
    margin: 0 15px;
  }

  .card__10_wrapper {
    margin-bottom: 15px;
  }
}

.newsletter__section {
  margin: 0 -100px;
  padding-top: 100px;

  @include m {
    margin: 0 -25px;
  }
}

.floating_button {
  position: fixed;
  top: calc(50% - 100px);
  left: 0;
  background-color: var(--primary-01);
  height: 200px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  z-index: 10000;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  color: var(--neutral-11);
  font-weight: 700;
  text-align: center;
  font-size: 20px;
  width: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid var(--neutral-01);
  border-width: 1.5px 1.5px 1.5px 0;
  transition: all 500ms ease-in-out;

  @include t {
    width: 70px;
    height: 185px;
  }

  @include m {
    display: none;
  }
}

.floating_button:hover {
  cursor: pointer;
  color: var(--neutral-11);
  background-color: var(--primary-01-01);
}

.floating_mobile_button {
  display: none;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: var(--primary-01);
  padding: 10px 30px;
  left: 0;
  z-index: 10000;
  align-items: center;
  justify-content: center;
  color: var(--neutral-11);

  h3 {
    color: var(--neutral-11);
    margin: 0;
    font-size: 30px;
    font-weight: 700;
  }

  p {
    color: var(--neutral-11);
    margin: 0;
    font-size: 16px
  }

  svg {
    fill: var(--neutral-11);
    width: 40px;
    height: 40px
  }

  @include m {
    display: flex;
  }

  &_details {
    margin-left: 30px
  }
}

.floating_mobile_button:hover {
  cursor: pointer;
}

.search__modal__title{
  color: var(--primary-01);
  font-size: 1.1rem;
  position: sticky;
  top: 0;
  background: var(--neutral-01);
  z-index: 2;
  padding: 10px 0;
}

.card9__wrapper_card {
  flex: 0 0 calc(33.333% - 32px);
  width: calc(33.333% - 32px);
  margin: 32px 12px 0;
  height: auto;

  @include t {
    flex: 0 0 calc(50% - 32px);
    width: calc(50% - 32px);
    margin: 48px 16px 0;
  }

  @include s {
    flex: 0 0 100%;
    width: 100%;
    margin: 0;
    margin-top: 20px;
  }
}

.multiselect-item-checkbox:has(input[disabled]) {
  opacity: 0.5;
}

option,
optgroup {
  background-color: var(--neutral-00)
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-text-fill-color: var(--neutral-07) !important;
  border: solid 2px var(--neutral-03-01) !important;
  -webkit-box-shadow: 0 0 0px 1000px var(--neutral-01) inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  // -webkit-text-fill-color: var(--neutral-07) !important;
  // border: solid 2px var(--neutral-shade-05-75) !important;
  // border: solid 2px var(--neutral-03-01) !important;
  // -webkit-box-shadow: 0 0 0px 1000px var(--neutral-01) inset  !important;
  // transition: background-color 5000s ease-in-out 0s;
}

.event__details {
  color: var(--neutral-04) !important;
}

.event__details * {
  color: var(--neutral-04) !important;
}

.grecaptcha-badge {
  visibility: hidden;
  bottom: -60px;
}

.pac-container {
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  background-color: var(--neutral-00) !important;
  border-width: 0px !important;
}

.pac-item {
  padding: 5px 10px !important;
  font-size: 15px !important;
  border-color: var(--neutral-03-01) !important;
}

.pac-item:hover {
  background-color: var(--neutral-03) !important;
}

.pac-item-query {
  color: var(--neutral-07) !important;
}

.entity__page-title {
  font-size: 40px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: -.02em;
  margin-bottom: 24px;
  padding-top: 1.5rem;
  color: var(--neutral-07)
}

.preview__section {
  .button-icon-secondary {
    height: inherit !important;
    min-width: inherit !important;
  }
}

.entity__preview {
  margin-top: 100px;
}

.entity__preview_image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  max-height: 275px;
  width: auto;

  &.pdf {
    height: 300px;
  }

  img,
  embed,
  object,
  iframe {
    width: 100%;
    max-height: inherit;
    border-radius: 12px;
    object-fit: cover;
    object-position: center;
  }

  img {
    max-height: 200px;
  }
}

.entity__preview_details {
  display: flex;
  align-items: flex-start;
}

.entity__preview_title {
  color: var(--neutral-07);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: -.02em;
  flex: 1;
}

.entity__preview_price {
  flex-shrink: 0;
  margin-left: 12px;
}
