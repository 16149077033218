@import "./variables";

.form {
  position: relative;
  width: 360px;
  &__input {
    width: 100%;
    height: 40px;
    padding: 0 20px 0 44px;
    background: var(--neutral-02);
    border-radius: 12px;
    @include base1-s;
    color: var(--neutral-07);
    // @include dark {
    //   background: var(--neutral-06);
    //   color: var(--neutral-01);
    // }
    &::placeholder {
      color: var(--neutral-shade-04-75);
    }
  }
  &__button {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 44px;
    padding-right: 4px;
    .icon {
      width: 24px;
      height: 24px;
      transition: fill 0.2s;

      svg {
        fill: var(--neutral-04);
      }
    }
    &:hover {
      .icon svg {
        fill: var(--primary-01);
      }
    }
  }
  &-check-input:checked {
    background-color: var(--primary-01);
    border-color: var(--primary-01-01);
  }
  &-check-input:focus {
    box-shadow: 0 0 0 0.25rem var(--primary-01-02);
  }
}

.checkbox {
  display: block;
  position: relative;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
  &__inner {
    display: flex;
    &:hover {
      .checkbox__tick {
        border-color: var(--primary-01);
      }
    }
  }
  &__tick {
    position: relative;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    border-radius: 6px;
    border: 2px solid var(--neutral-shade-dark-04-40);
    transition: all 0.2s;
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 17px;
      height: 12px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='12' viewBox='0 0 17 12'%3E%3Cpath d='M16.707.293a1 1 0 0 1 0 1.414l-8.586 8.586a3 3 0 0 1-4.243 0L.293 6.707A1 1 0 0 1 .735 5.02a1 1 0 0 1 .973.273l3.586 3.586a1 1 0 0 0 1.414 0L15.293.293a1 1 0 0 1 1.414 0z' fill='%23fcfcfc' fill-rule='evenodd'/%3E%3C/svg%3E")
        no-repeat 50% 50% / 100% auto;
      opacity: 0;
      transition: opacity 0.2s;
    }
  }
  &__text {
    flex-grow: 1;
    padding-left: 16px;
    font-size: 15px;
    line-height: calc(24 / 14);
    font-weight: 600;
    color: var(--neutral-07);
  }
  &__input:checked + &__inner &__tick {
    background: var(--primary-01);
    border-color: var(--primary-01);
    &:before {
      opacity: 1;
    }
  }
  &_reverse {
    .checkbox__inner {
      flex-direction: row-reverse;
    }
    .checkbox__text {
      padding: 0 16px 0 0;
    }
  }
}
