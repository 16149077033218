/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: 'Geometry';
  src: url('../src/assets/styles/fonts/geometry.woff') format('truetype');
  /* Add more src properties for different font weights or styles if available */
}

button {
  cursor: pointer;
}

input,
button {
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
}

textarea {
  overflow: auto;
}

div,
input,
textarea,
select,
button,
h1,
h2,
h3,
h4,
h5,
h6,
a,
span,
a:focus {
  outline: none;
}

[class^="title"] {
  position: relative;
  display: inline-block;
  padding-left: 32px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: -0.02em;
}

@media only screen and (max-width: 767px) {
  [class^="title"] {
    font-size: 18px;
  }
}

[class^="title"]:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 16px;
  height: 32px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 4px;
}

.title-side:before {
  background: var(--custom-01);
}

body {
  .editor {
    editor {
      .tox-tinymce {
        border: 2px solid var(--neutral-shade-04-40) !important;
        border-radius: 12px !important;

        &:focus,
        &:hover {
          border: 2px solid var(--neutral-shade-04-75) !important;
        }

        .tox-toolbar__primary,
        .tox-toolbar__overflow {
          background: none !important;
          background-color: var(--neutral-01) !important;
        }

        .tox-toolbar__primary {
          border-bottom: 1px solid var(--neutral-shade-04-40) !important;
        }

        .tox-toolbar__group {
          border-color: var(--neutral-shade-04-40) !important;
        }

        .tox-tbtn {
          color: var(--neutral-07) !important;

          svg {
            fill: var(--neutral-07) !important;
          }

          .tox-tbtn__icon-wrap {
            svg {
              fill: var(--neutral-07) !important;
            }
          }
        }

        .tox-tbtn:hover {
          background-color: var(--primary-01-02) !important;
        }

        .tox-edit-area__iframe {
          background-color: var(--neutral-02) !important;
        }

        .tox-statusbar {
          border-top-color: var(--neutral-shade-04-40) !important;
          background: var(--neutral-01) !important;

          .tox-statusbar__wordcount,
          .tox-statusbar__path-item {
            color: var(--neutral-05) !important;
          }
        }
      }
    }
  }
}

.tox {
  .tox-tiered-menu {
    .tox-menu {

      .tox-collection__item--active,
      .tox-collection__item--enabled {
        background-color: var(--primary-01-02);
      }
    }
  }
}


.modal {
  &-backdrop {
    background-color: var(--neutral-02);

    &.show {
      opacity: 0.9;
    }
  }

  &-content {
    background-color: var(--neutral-00);
    z-index: 3;
    width: 100%;
    padding: 24px;
    margin: auto;
    border-radius: 16px;
    border: none;
    box-shadow: 0px 0px 14px -4px rgb(0 0 0 / 5%),
      0px 32px 48px -8px rgb(0 0 0 / 10%);
    transition: opacity 0.5s;
  }

  &-dialog {
    margin-right: auto;
    margin-left: auto;
  }

  &-header {
    border-bottom: 1px solid var(--neutral-03);
    padding: 0 0 1rem 0;
  }

  &-title {
    color: var(--neutral-07);
  }

  &-body {
    color: var(--neutral-04);
  }

  &-footer {
    border-top: 1px solid var(--neutral-03);
    padding: 0.75rem 0 0;
    gap: 1rem;
    justify-content: center;
  }

  &-md {
    max-width: 400px;
  }

  &__close {
    flex-shrink: 0;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: var(--neutral-03);
    margin-left: 24px;

    &:hover {
      svg {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
      }
    }

    svg {
      width: 20px;
      height: 20px;
      fill: var(--neutral-07);
      transition: -webkit-transform 0.2s;
      transition: transform 0.2s;
      transition: transform 0.2s, -webkit-transform 0.2s;
    }
  }
}

.swal {
  &-overlay {
    background-color: var(--neutral-shade-dark-04-40);
  }

  &-modal {
    background-color: var(--neutral-01);
    border-radius: 12px;
  }

  &-title {
    color: var(--neutral-07);
  }

  &-text {
    text-align: center;
    color: var(--neutral-04);
  }

  &-icon--success__line {
    background-color: var(--primary-02);
  }

  &-icon--error__line {
    background-color: var(--primary-03);
  }

  &-icon--warning__body,
  &-icon--warning__dot {
    background-color: var(--secondary-05);
  }

  &-icon--success__ring {
    border-color: var(--primary-02-01);
  }

  &-icon--error {
    border-color: var(--primary-03-01);
  }

  &-icon--warning {
    border-color: var(--secondary-05-01) !important;
  }

  &-icon--info {
    border-color: var(--secondary-03-01);
  }

  &-icon--success__hide-corners {
    visibility: hidden;
  }

  &-icon--success:after,
  &-icon--success:before {
    background-color: var(--neutral-01);
  }

  &-icon--info:after,
  &-icon--info:before {
    background-color: var(--secondary-03);
  }

  &-button.swal-button--confirm {
    background-color: var(--primary-01);
    color: var(--neutral-11);
    border: none;

    &:hover {
      background-color: var(--primary-01-01);
    }

    &:focus {
      box-shadow: none;
    }
  }

  &-button.swal-button--danger {
    background-color: var(--danger-01);

    &:hover {
      background-color: var(--danger-01-01);
    }

    &:focus {
      box-shadow: none;
    }
  }

  &-button.swal-button--cancel {
    &:focus {
      box-shadow: none;
    }
  }

  &-button.swal-button--cart {
    background-color: var(--secondary-06);
    color: var(--neutral-11);

    &:hover {
      background-color: var(--secondary-07);
    }
  }
}

.backdrop {
  z-index: 1 !important;
}

::-ms-reveal {
  display: none;
}