// media queries
@mixin w {
  @media only screen and (max-width: "1419px") {
    @content;
  }
}

@mixin x {
  @media only screen and (max-width: "1339px") {
    @content;
  }
}

@mixin d {
  @media only screen and (max-width: "1259px") {
    @content;
  }
}

@mixin d-min {
  @media only screen and (min-width: "1259.89px") {
    @content;
  }
}

@mixin t {
  @media only screen and (max-width: "1024px") {
    @content;
  }
}

@mixin t-min {
  @media only screen and (min-width: "1023.89px") {
    @content;
  }
}

@mixin m {
  @media only screen and (max-width: "768px") {
    @content;
  }
}

@mixin m-min {
  @media only screen and (min-width: "767.89px") {
    @content;
  }
}

@mixin a {
  @media only screen and (max-width: "639px") {
    @content;
  }
}

@mixin sm {
  @media only screen and (min-width: "576px") {
    @content;
  }
}

@mixin sm-max {
  @media only screen and (max-width: "576.89px") {
    @content;
  }
}

@mixin s {
  @media only screen and (max-width: "474px") {
    @content;
  }
}
@mixin s-min {
  @media only screen and (min-width: "474px") {
    @content;
  }
}
@mixin xs {
  @media only screen and (max-width: "370px") {
    @content;
  }
}

// width
@mixin r($width) {
  @media only screen and (max-width: $width) {
    @content;
  }
}

@mixin rmin($width) {
  @media only screen and (min-width: $width) {
    @content;
  }
}

// height
@mixin rh($height) {
  @media only screen and (max-height: $height) {
    @content;
  }
}

@mixin rhmin($height) {
  @media only screen and (min-height: $height) {
    @content;
  }
}

// typography
@mixin heading {
  font-size: 64px;
  font-weight: 700;
  line-height: calc(32 / 25);
  letter-spacing: -0.02em;
  @include t {
    font-size: 54px;
  }
  @include m {
    font-size: 44px;
  }
}

@mixin title-lg {
  font-size: 40px;
  font-weight: 700;
  line-height: calc(32 / 20);
  letter-spacing: -0.02em;
  @include t {
    font-size: 30px;
  }
  @include m {
    font-size: 20px;
  }
}
@mixin title-md {
  font-size: 30px;
  font-weight: 600;
  line-height: calc(32 / 20);
  letter-spacing: -0.02em;
  @include t {
    font-size: 20px;
  }
  @include m {
    font-size: 18px;
  }
}
@mixin title1-md {
  font-size: 36px;
  font-weight: 600;
  line-height: calc(32 / 20);
  letter-spacing: -0.02em;

  @include m {
    font-size: 24px;
  }

  @include sm-max {
    font-size: 20px;
  }
}

@mixin title1-s {
  font-size: 20px;
  font-weight: 600;
  line-height: calc(32 / 20);
  letter-spacing: -0.02em;

  @include m {
    font-size: 18px;
  }
}

@mixin title1-m {
  font-size: 20px;
  font-weight: 500;
  line-height: calc(32 / 20);
  letter-spacing: -0.02em;

  @include m {
    font-size: 18px;
  }
}

@mixin title2 {
  font-size: 18px;
  line-height: calc(24 / 18);
  letter-spacing: -0.02em;
}

@mixin subtitle {
  font-size: 20px;
  line-height: calc(32 / 20);
  letter-spacing: -0.02em;

  @include m {
    font-size: 18px;
  }
}

@mixin base1-t {
  font-size: 16px;
  font-weight: 600;
  line-height: calc(24 / 15);
}

@mixin base1-s {
  font-size: 15px;
  font-weight: 600;
  line-height: calc(24 / 15);
}

@mixin base1-b {
  font-size: 15px;
  font-weight: 700;
  line-height: calc(24 / 15);
}

@mixin base2 {
  font-size: 14px;
  font-weight: 600;
  line-height: calc(24 / 14);
}
@mixin body-n {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: -0.015em;
}
@mixin body1-m {
  font-size: 15px;
  font-weight: 500;
  line-height: calc(24 / 15);
  letter-spacing: -0.015em;
}

@mixin body1-s {
  font-size: 15px;
  font-weight: 600;
  line-height: calc(24 / 15);
  letter-spacing: -0.015em;
}

@mixin body2-s {
  font-size: 14px;
  font-weight: 600;
  line-height: calc(24 / 14);
}

@mixin caption1 {
  font-size: 13px;
  font-weight: 600;
  line-height: calc(16 / 13);
}

@mixin caption1-m {
  font-size: 13px;
  font-weight: 500;
  line-height: calc(16 / 13);
}

@mixin caption2 {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
}

@mixin caption2-m {
  font-size: 12px;
  font-weight: 300;
  line-height: calc(16 / 11);
}

@mixin button1 {
  font-size: 15px;
  font-weight: 700;
  line-height: calc(24 / 15);
}

@mixin button2 {
  font-size: 13px;
  font-weight: 700;
  line-height: calc(24 / 13);
}

@mixin avatar {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}

@mixin loader {
    margin-right: 8px;
    height: 22px;
    width: 22px;
    border-width: 2px;
    color: var(--primary-01);
}
