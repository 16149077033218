.entry {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(var(--vh, 1vh) * 100);
  padding: 48px;
  background: var(--neutral-01);

  &__or-label {
    &::before {
      content: "";
      position: absolute;
      width: 43%;
      height: 2px;
      background: var(--neutral-03-01);
      right: 0px;
      top: 50%;
    }
    color: var(--neutral-03-01);
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 43%;
      height: 2px;
      background: var(--neutral-03-01);
      left: 0px;
      top: 50%;
    }
  }
}

@media only screen and (max-width: 767px) {
  .entry {
    padding: 24px;
  }
}

body.dark .entry {
  background: var(--neutral-07);
}

.entry__wrapper {
  max-width: 400px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .entry__wrapper {
    max-width: 100%;
  }
}

.entry__logo {
  display: inline-block;
  width: 150px;
  margin-bottom: 25px;
}

.entry__logo img {
  width: 100%;
}

.entry__title {
  margin-bottom: 32px;
  font-size: 48px;
  line-height: 1;
  letter-spacing: -0.03em;
  font-weight: 600;
  color: var(--neutral-07);
}

.entry__sub-title {
  margin-bottom: 26px;
  font-size: 38px;
  line-height: 1;
  letter-spacing: -0.03em;
  font-weight: 600;
  color: var(--neutral-04);
}

.entry__top {
  padding-bottom: 32px;
  border-bottom: 2px solid var(--neutral-03);

  &.or {
    padding-bottom: 20px;
    border: none;
  }
}

@media only screen and (max-width: 767px) {
  .entry__top {
    padding-bottom: 24px;
  }
}

body.dark .entry__top {
  border-color: var(--neutral-06);
}

.entry__text {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71429;
  color: var(--neutral-07);
}

.entry__sub-text {
  font-size: 14px;
  font-weight: 600;
}

.entry__checkbox {
  margin-top: 2px !important;
}

.entry__btns {
  display: flex;
  margin: 0 -4px;
}

// ::ng-deep {
  .entry__button {
    flex: 0 0 calc(100% - 8px);
    width: calc(100% - 8px);
  }
// }

.entry__btn {
  flex: 1;
}

.button-secondary {
  padding: 0 16px !important;
}

.entry .field {
  margin-bottom: 12px;
}

.entry__note {
  margin-top: 32px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71429;
  color: var(--neutral-shade-04-75);
}

@media only screen and (max-width: 767px) {
  .entry__note {
    margin-top: 24px;
  }
}

.entry__info {
  margin-top: 32px;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.23077;
  color: var(--neutral-04);
}

@media only screen and (max-width: 767px) {
  .entry__info {
    margin-top: 24px;
  }
}

.entry__info a {
  font-weight: 700;
  // color: var(--neutral-07);
  transition: color 0.2s;
}

body.dark .entry__info a {
  color: var(--neutral-01);
}

.entry__info a:hover {
  color: var(--primary-01);
}

body.dark .entry__info a:hover {
  color: var(--primary-01);
}

.field__wrap {
  position: relative;
}

.form-error {
  color: var(--primary-03);
  font-size: 14px;
  font-weight: 500;
  margin-top: 16px;
}
