@import "./variables";
@import "./form";

.table {
  display: table;
  width: 100%;

  &> :not(caption)>*>* {
    padding: 0px;
    background-color: transparent;
    color: var(--neutral-07);
  }

  @include t {
    display: block;
  }

  &__wrapper {
    margin: 0 -12px;

    @include t {
      margin: 0;
    }
  }

  &__row {
    display: table-row;
    position: relative;

    &:first-child {
      @include t {
        display: none;
      }

      .table__col {
        padding: 0 12px 16px;
        @include caption1;
        color: var(--neutral-04);
      }
    }

    &:not(:first-child) {
      transition: background 0.2s;

      @include t {
        position: relative;
        display: block;
        margin-bottom: 24px;
        // padding-bottom: 24px;
        border-bottom: 1px solid var(--neutral-03);
      }

      &:hover {
        // @include rmin(1260px) {
        background: var(--neutral-02);

        .table__control {
          visibility: visible;
          opacity: 1;
        }

        // }
      }
    }
  }

  &__col {
    position: relative;
    display: table-cell;
    padding: 16px 12px;
    vertical-align: middle;
    color: var(--neutral-07);

    @include t {
      position: static;
      display: flex;
      align-items: center;
      padding: 0;
      margin-bottom: 12px;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      height: 1px;
      background: var(--neutral-03);

      @include t {
        display: none;
      }
    }

    &:first-child {
      width: 56px;
      border-radius: 8px 0 0 8px;

      // font-size: 0;
      @include t {
        display: none;
      }

      &:after {
        left: 12px;
      }
    }

    &:last-child {
      padding-right: 164px;
      border-radius: 0 8px 8px 0;
      color: var(--neutral-04);

      @include t {
        padding: 0;
      }

      &:after {
        right: 12px;
      }
    }

    &:not(:nth-child(2)) {
      @include t {
        // display: none;
        margin-bottom: 12px;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: color 0.2s;
    width: max-content;

    @include m {
      align-items: stretch;
    }

    @include sm-max {
      flex-wrap: wrap;
      gap: 0.5rem;
    }

    &:hover {
      color: var(--primary-01);
    }

    .table__price,
    .table__empty,
    .table__date {
      display: none;
    }

    .table__price,
    .table__empty {
      @include t {
        display: inline-block;
      }
    }

    .table__date {
      @include t {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        @include caption1;
        color: var(--neutral-shade-04-75);

        .icon {
          width: 20px;
          height: 20px;
          margin-right: 8px;
          fill: var(--neutral-shade-04-75);
        }
      }
    }

    .table__product {
      color: var(--neutral-07);
    }

    &:hover {
      .table__product {
        color: var(--primary-01);
      }
    }
  }

  &__preview {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    margin-right: 20px;
    border-radius: 8px;
    overflow: hidden;

    @include t {
      width: 104px;
      height: 116px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__product {
    max-width: 170px;
    margin-bottom: 4px;
    font-weight: 700;

    @include t {
      max-width: 100%;
      // margin-bottom: 8px;
      padding-right: 44px;
      @include title2;
    }
  }

  &__description {
    @include caption1;
    color: var(--neutral-shade-04-75);
    // @include t {
    //   display: none;
    // }
  }

  &__price,
  &__empty {
    flex-shrink: 0;
    display: inline-block;
    padding: 0 8px;
    border-radius: 6px;
    line-height: 32px;
    font-weight: 700;
  }

  &__price {
    background: var(--secondary-04);
  }

  &__empty {
    background: var(--neutral-03);
  }

  &__label {
    display: none;

    @include t {
      display: block;
      flex-shrink: 0;
      width: 124px;
      padding-right: 20px;
      @include caption1;
      color: var(--neutral-04);
    }
  }

  &__details {
    width: 250px;

    @include t {
      width: auto;
      margin-right: 25px;
    }
  }

  &__value {
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
  }

  &__value-hide {
    display: flex;
    align-items: center;

    @include t {
      display: none !important;
    }
  }

  &__control {
    position: absolute;
    top: 50%;
    right: 12px;
    display: flex;
    align-items: center;
    transform: translateY(-50%);
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s;
    z-index: 1;

    @include d {
      visibility: visible;
      opacity: 1;
    }

    @include t {
      display: none;
    }

    &.bottom-right {
      bottom: 0;
      transform: translateY(50%);

      @include d {
        visibility: hidden;
        opacity: 0;
      }
    }

    // &.center {
    //   bottom: 0;
    //   left: 0;
    //   right: 0;
    //   justify-content: center;
    // }

    &.bottom-center {
      bottom: -6%;
      top: unset;
      left: 0;
      right: 0;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__control &__button {
    flex-shrink: 0;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: var(--neutral-01);

    @include d {
      background: var(--neutral-03);
    }

    .icon,
    svg {
      width: 20px;
      height: 20px;
      color: var(--neutral-04);
      transition: color 0.2s;
    }

    &:hover {

      .icon,
      svg {
        color: var(--primary-01);
      }
    }

    &.success:hover {

      .icon,
      svg {
        color: var(--success-01);
      }
    }

    &.danger:hover {

      .icon,
      svg {
        color: var(--danger-01);
      }
    }

    &.warning:hover {

      .icon,
      svg {
        color: var(--secondary-05);
      }
    }

    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  .actions {
    display: none;

    @include t {
      display: block;
      position: absolute;
      top: 0;
      right: 0;

      // z-index: 2;
      &.active {
        z-index: 10;
      }
    }
  }

  &__foot {
    margin-top: 20px;
    text-align: center;

    @include t {
      margin-top: 0;
    }
  }

  &__foot &__button .loader {
    transform: scale(0.8);
    margin: 0 18px 0 8px;
  }

  .more__actions {
    display: block;
    position: absolute;
    top: 0;
    right: 0;

    // z-index: 2;
    &.active {
      z-index: 10;
    }
  }

  .table__row:hover {
    .event__category {
      .pill {
        color: var(--neutral-07) !important;
      }
    }

    .pill {
      background: var(--neutral-01) !important;
    }
  }
}

.table__control.center {
  bottom: 16%;
  left: 12%;
  top: initial;
  right: initial;
  transform: none;
  z-index: 1;
}

.centered__actions {
  .table__row:not(:first-child) {
    .table__col {
      vertical-align: top;
    }
  }

  .table__col:last-child {
    padding-right: 0px;
  }
}

.table__button.primary:hover,
.table__button.primary:focus {
  svg {
    fill: var(--primary-01) !important;
  }
}

.table__button.success:hover,
.table__button.success:focus {
  svg {
    fill: var(--success-01) !important;
  }
}

.table__button.danger:hover,
.table__button.danger:focus {
  svg {
    fill: var(--danger-01) !important;
  }
}
