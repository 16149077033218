@import "./variables";

.panel__row {
  display: flex;

  @include t {
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 15px;
  }

  @include a {
    flex-direction: column;
    flex-wrap: nowrap;
    column-gap: 15px;
  }
}

.location {
  overflow: hidden;
  flex: 1;

  @include t {
    flex: 0 0 50%;
    width: 50%;
  }

  @include a {
    flex: 0 0 100%;
    width: 100%;
  }
}

.price__range {
  width: 80% !important;
  padding-right: 12px;
}

.location__head {
  display: flex;
  align-items: flex-start;
  width: 95%;
}

.location__icon {
  margin-right: 10px;
  pointer-events: none;
}

.location__icon svg {
  width: 24px;
  height: 24px;
  fill: var(--neutral-10);
}

.location__input {
  width: calc(100% - 34px);
  font-size: 16px;
  line-height: 1.5;
  color: var(--neutral-04);
  cursor: pointer;
  margin-left: 34px;
}

input[type="text"] {
  -webkit-appearance: none;
}

.location__description {
  font-size: 16px;
  line-height: 1.5;
  color: var(--neutral-04);
  padding-left: 34px;
}

.location__radius {
  width: 80%;
  margin-left: 34px !important;
  padding: 0 12px 0 4px;

  .label {
    margin-bottom: 0 !important;
  }
}

.location__head_btn {
  flex: 1;
  display: flex;
  justify-content: flex-end;

  svg {
    fill: var(--neutral-07);
  }
}

.location__head_btn.collapsed svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.datepicker {
  // position: relative;
  flex: 1;
}

.datepicker__wrapper {
  display: flex;
}

::placeholder {
  color: var(--neutral-04);
}

::-webkit-input-placeholder {
  color: var(--neutral-04);
}

:-ms-input-placeholder {
  color: var(--neutral-04);
}

.category__head {
  display: flex;
}

.category__content,
.travelers__content {
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -.01em;
  color: var(--neutral-07);
}

.datepicker__icon {
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  pointer-events: none;
  margin-right: 5px;
}

.datepicker__icon svg {
  width: 24px;
  height: 24px;
  fill: var(--neutral-10);
}

.category__search {
  flex: 1.75;
  display: flex;
}

.category {
  flex: 1;
  padding: 0 10px;
}

.category__select {
  padding-left: 34px;
}

.search {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.panel__search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  transition: background .2s;
  height: 64px;
  background: var(--primary-01);
  border-radius: 50%;
}

.panel__search:hover {
  background: var(--primary-01-01);
  cursor: pointer;
}

.panel__search svg {
  width: 24px;
  height: 24px;
  fill: var(--neutral-11);
}

.custom__filter {
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  box-shadow: inset 0 1px 0 0 var(--neutral-03);
  padding-top: 20px;
  margin-top: 20px;

  &.flex__spacing {
    gap: 1.5rem;
  }
}

.filters {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  flex: 1;
  column-gap: 25px;

  &.flex__spacing {
    gap: 1.5rem;
  }
}

.filter__item {
  display: flex;
  flex-direction: column;
  width: calc(calc(100% - 50px) / 3);
  margin-bottom: 20px;
  // margin-right: 25px;

  &.flex__spacing {
    width: 100%;
    margin-bottom: unset;
    margin-right: unset;
  }
}

.label {
  align-items: center;
  justify-content: space-between;
  line-height: 24px;
  margin-bottom: 14px !important;
  color: var(--neutral-05);
  font-size: 14px;
  font-weight: 600;
}

.label.location__label {
  font-size: 18px;
}

// .filter__item:not(:first-child) {
//   margin: 0 25px;
// }

// .filter__item:first-child {
//   margin-right: 25px;
// }

.panel__body {
  .category {
    .category__select {
      margin-top: -10px !important;

      .select {
        .multiselect-dropdown {
          .dropdown-btn {
            box-shadow: none !important;
            padding-left: 3px !important;
          }
        }

        width: 50% !important;
      }
    }
  }
}

.filter__price {
  .noUi-connects {
    background: var(--neutral-03-01) !important;
  }
}

.review__box {
  position: relative;
}

.review__box .select {
  padding-left: 48px;
}

.review__box svg {
  position: absolute;
  left: 12px;
  bottom: 12px;
  z-index: 10;
  pointer-events: none;
  width: 24px;
  height: 24px;
  fill: var(--secondary-05);
}

.field__wrap {
  position: relative;
}

@media only screen and (max-width: 1179px) {
  .location__input {
    font-size: 18px;
  }

  .location__description {
    font-size: 14px;
  }

  .datepicker__input {
    font-size: 18px;
  }

  .datepicker__description {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1179px) {
  .panel__row {
    // flex-direction: column;
    margin: 0;
  }

  .datepicker__icon,
  .location__icon {
    display: block;
  }

  .location__description,
  .datepicker__description,
  .category__select {
    padding-left: 34px;
  }

  .datepicker {
    padding: 20px 0;
  }

  .category {
    padding: 0px;
  }

  .category,
  .search {
    width: 50%;
  }

  .search {
    justify-content: flex-start;
    padding-left: 10px;
  }

  .custom__filter {
    flex-direction: column;
  }

  /*.button-primary {
    width: 100%;
  }*/

  .filter__item {
    margin: 0px;
    margin-bottom: 15px;
    width: calc(calc(100% - 25px) / 2);
  }

  .filter__item:nth-child(1),
  .filter__item:nth-child(3),
  .filter__item:nth-child(5),
  .filter__item:nth-child(7),
  .filter__item:nth-child(9) {
    // margin-right: 5px;
  }
}

@media only screen and (max-width: 474px) {
  .filters {
    flex-direction: column;
  }

  .filter__item {
    width: 100%;
  }

  .category {
    width: 100%;
  }

  .search {
    width: auto;
    padding-left: 20px;
  }

  .category__search {
    flex-direction: column;
  }
}

@media only screen and (max-width: 480px) {

  .filter__item:nth-child(1),
  .filter__item:nth-child(3),
  .filter__item:nth-child(5),
  .filter__item:nth-child(7),
  .filter__item:nth-child(9) {
    margin-right: unset;
  }
}