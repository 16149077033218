@import "./variables";

.button {
  & a {
    text-decoration: none;
  }

  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 12px;
  position: relative;

  border-radius: 12px;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  font-weight: bold;
  transition: all 0.2s;
  white-space: nowrap;

  &-youtube {
    background-color: var(--social-youtube);
    border-color: var(--social-youtube);
    span {
      color: white !important;
    }

    :hover {
      opacity: 0.8;
    }
  }

  &:hover {
    cursor: pointer;
  }

  .loader {
    margin-right: 8px;
    height: 22px;
    width: 22px;
    border-width: 2px;
    color: inherit;
  }

  .image {
    width: 24px;
    margin-right: 10px;
  }
}

.button.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.button.button-primary {
  background: var(--primary-01);
  color: var(--neutral-11);

  svg {
    fill: var(--neutral-11);
  }

  &:hover {
    background: var(--primary-01-01);
  }

  span {
    color: var(--neutral-11);
  }
}

.button.button-secondary {
  background: var(--neutral-13);

  span {
    color: var(--neutral-01) !important;
  }

  svg {
    color: var(--neutral-01);
  }

  &:hover {
    opacity: 0.7;
  }
}

.button.button-danger {
  background: var(--danger-01);
  color: var(--neutral-11);

  svg {
    fill: var(--neutral-11);
  }

  &:hover {
    background: var(--danger-01-01);
  }

  span {
    color: var(--neutral-11);
  }
}

.button.button-success {
  background: var(--success-01);
  color     : var(--neutral-11);

  svg {
    fill: var(--neutral-11);
  }

  &:hover {
    background: var(--success-01-01);
  }

  span {
    color: var(--neutral-11);
  }
}

.button.button-tertiary {
  background: var(--secondary-06);
  color: var(--neutral-11);

  svg {
    fill: var(--neutral-11);
  }

  &:hover {
    background: var(--secondary-07);
  }

  span {
    color: var(--neutral-11);
  }
}

.button.lg {
  min-width: 292px !important;
}

.button.md {
  min-width: 197.3px !important;
}

.button.sm {
  min-width: 36px !important;
  height: 36px;
}

.button.button-loading {
  min-width: 116px !important;
  height: 40px;
  background-color: var(--neutral-01);
  border: 2px solid var(--neutral-03);
  border-radius: 8px;
  padding: 8px 16px;
  color: var(--neutral-07);
  font-size: 13px;
  line-height: 24px;
  font-weight: 700;

  &:hover {
    border-color: var(--neutral-04);
  }
}

.button-icon-primary {
  background: var(--primary-01);
  border-radius: 64px;
  padding: 0;

  svg {
    color: var(--neutral-11);
  }

  &:hover {
    background: var(--primary-01-01);
  }
}

.button-icon-secondary {
  background: var(--neutral-01);
  border-radius: 36px;
  padding: 0;

  svg {
    color: var(--neutral-shade-04-75);
  }

  &:hover {
    svg {
      color: var(--primary-01);
    }
  }
}

.button-icon-secondary-2 {
  background: var(--neutral-13);
  border-radius: 64px !important;
  padding: 0 !important;

  svg {
    color: var(--neutral-01);
  }

  &:hover {
    opacity: 0.7;
  }
}

.button-icon-tertiary {
  background: transparent;
  min-width: 48px !important;
  height: 48px;

  svg {
    color: var(--neutral-04);
  }

  &:hover {
    svg {
      color: var(--primary-01);
    }
  }
}

.button-icon-tertiary-2 {
  background: var(--secondary-06);
  border-radius: 64px !important;
  padding: 0 !important;

  svg {
    color: var(--neutral-11);
  }

  &:hover {
    background: var(--secondary-07);
  }
}

.button-icon-danger {
  background: transparent;
  height: 48px;

  svg {
    color: var(--neutral-04);
  }

  &:hover {
    svg {
      color: var(--danger-01);
    }
  }
}

.button-icon-sm {
  min-width: 36px !important;
  height: 36px;
  border: 2px solid var(--neutral-03);

  svg {
    width: 20px;
    height: 20px;
  }
}

.button-icon-md {
  min-width: 64px !important;
  height: 64px;

  svg {
    width: 24px;
    height: 24px;
  }
}

.button-icon-lg {
  min-width: 100px !important;
  height: 100px;

  svg {
    width: 30px;
    height: 30px;
  }
}

.button-image {
  background: var(--neutral-11);
  border: 2px solid transparent;
  border-radius: 64px;
  padding: 0;

  min-width: 50px;
  height: 50px;
  width: min-content;

  img {
    @include avatar;
  }
}

.button-image-lg {
  min-width: 100px;
  height: 100px;

  img {
    @include avatar;
  }
}

.button-image-md {
  min-width: 64px;
  height: 64px;

  img {
    @include avatar;
  }
}

.button-image-sm {
  min-width: 36px;
  height: 36px;

  img {
    @include avatar;
  }
}

.highlight {
  border: 2px solid var(--neutral-03);
}

.active {
  visibility: visible;
  opacity: 1;
}

.round {
  border-radius: 20px;
}

.min-width-auto {
  min-width: auto !important;
}

@media only screen and (max-width: 767px) {
  .button.lg {
    width: 100%;
  }
}
