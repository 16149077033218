@import "./variables";

.dropdown {
  &__body {
    position: absolute;
    z-index: 10;
    border: 1px solid transparent;
    border-radius: 16px;
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08),
      0px 0px 14px -4px rgba(0, 0, 0, 0.05),
      0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    background: var(--neutral-00);
    width: 280px;
    top: calc(100% + 4px);
    padding: 16px;
    visibility: hidden;
    opacity: 0;
    transform: translateY(0);
    transition: all 0.2s;
    right: 0;

    &:before {
      content: "";
      position: absolute;
      bottom: 100%;
      right: 34px;
      width: 20px;
      height: 10px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto;
    }
  }

  &__head {
    width: 48px;
    height: 48px;

    &.header__text {
      width: auto;
    }
  }

  &__item_user &__head {
    border-radius: 50%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  &__item {
    position: relative;

    &.active {
      .dropdown__body {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  &__nav {
    &:not(:last-child) {
      margin-bottom: 12px;
      padding-bottom: 12px;
      border-bottom: 1px solid var(--neutral-03);
    }
  }

  &__link {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 12px;
    font-weight: 600;
    border-radius: 12px;
    color: var(--neutral-04);
    transition: all 0.2s;

    a {
      color: var(--neutral-04);
    }

    .icon,
    svg {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      fill: var(--neutral-04);
      transition: fill 0.2s;
    }

    &:hover,
    &.active {
      color: var(--neutral-07);
      background-color: var(--neutral-03);

      .icon,
      svg {
        fill: var(--neutral-07);
      }
    }

    &.active {
      background: var(--neutral-02);
    }

    &.color {
      color: var(--primary-04);

      .icon,
      svg {
        fill: var(--primary-04);
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &-toggle::after,
  .dropup &-toggle::after {
    display: none;
  }

  &-menu {
    width: 250px;
    z-index: 1032;
    padding: 0.5rem 0.5rem !important;
    margin: 0px !important;
    border: 1px solid transparent;
    border-radius: 16px;
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08),
      0px 0px 14px -4px rgba(0, 0, 0, 0.05),
      0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    background: var(--neutral-00) !important;

    &:before {
      content: "";
      position: absolute;
      bottom: 100%;
      border-left: solid 15px transparent;
      border-right: solid 15px transparent;
      border-bottom: solid 15px var(--neutral-00);
      height: 0;
      width: 0;
      display: none;
    }

    @include s {
      width: auto;
    }
  }

  &-menu_end {
    top: 15px !important;

    &::before {
      right: 8px;
      display: block;
    }
  }

  &-user_menu {
    top: 15px !important;

    &::before {
      right: 9px;
      display: block;
    }
  }

  &-menu_end_xl {
    top: 30px !important;

    &::before {
      right: 8px;
      display: block;
    }
  }

  &-menu_start {
    &::before {
      left: 10px !important
    }
  }

  &-user_profile {
    height: auto;
    align-items: flex-start;
    flex-direction: column;
    gap: .5rem;
    padding-top: 5px;
    padding-bottom: 5px;

    &_email {
      font-size: 13px;
      font-weight: normal;
      line-height: 1.14286;
      color: var(--neutral-shade-04-75);
    }

    &_labels {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      gap: .5rem;
    }
  }
}

.active {
  visibility: visible;
  opacity: 1;
}

// .select__dropdown{
// width: min-content;
// min-width: 175px;
// max-width: 275px;
// }
